import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { dumpVars, isNullOrUndefined, uniq } from 'utils/utils';
import { Dropdown } from 'primereact/dropdown';
import useCountries from '../../../hooks/use-countries/useCountries';
import { ApiContext } from '../../../api/api';
import {
  LovCompanyLocationWithOpeningHours,
  LovDTO,
  ModuleSettingsOutDTO,
  ModuleTypeEnum,
} from '../../../api/logsteo-api.v2';
import { AuthenticatedUserContext } from '../../AuthenticatedUser/AuthenticatedUser';

interface ComponentProps {
  companyId: string;
  companyLocationId: string;
  onChange: (companyLocation: LovCompanyLocationWithOpeningHours) => void;
  style?: any;
  moduleName: ModuleTypeEnum;
  disabled?: boolean;
}

const InputCompanyLocation: React.FC<ComponentProps> = ({
  companyLocationId,
  companyId,
  onChange,
  moduleName,
  style,
  disabled = false,
}) => {
  const { loggedUser } = useContext(AuthenticatedUserContext);
  const [options, setOptions] = useState<LovCompanyLocationWithOpeningHours[]>([]);
  const { lovGetAllLocationsForCompanyId } = useContext(ApiContext);

  useEffect(() => {
    if (companyId) {
      lovGetAllLocationsForCompanyId('', moduleName, companyId, (d) => {
        setOptions(d);
        if (companyLocationId) {
          if (d.find((t) => t.id === companyLocationId) == null) {
            onChange(null);
          }
        }
      });
    }
  }, [companyId]);

  return (
    <>
      {loggedUser && (
        <Dropdown
          style={style}
          value={{ id: companyLocationId }}
          options={options}
          optionLabel={'name'}
          dataKey={'id'}
          onChange={(e) => {
            onChange(e.value);
          }}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default InputCompanyLocation;
